import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Reactmarkdown from "react-markdown"
import { SEO } from "../components/seo"
import Layout from "../components/layout"
import BlocksRenderer from "../components/blocks-renderer"
import SgHeadings from "../components/sg-headings"
import Cta from "../components/call_to_action"



const ArticlePage = ({ data }) => {
  
  // define vars

  const article = data.strapiArticle
  const background = getImage(article?.cover?.localFile)
  const date = article.createdDate
  const author = article.author.name
  const bio = article.author.bio
  const email = article.author.email
  const headShot = getImage(article.author.avatar.localFile)
  
  // const shareImage = data.strapiArticle.cover.localFile.url  
  //console.log(shareImage)

  return (

    <Layout as="article">
      <header className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt={article.title}
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={article.title} description={article.description} />
                </div>  
      </header>
      <div className="p-2"></div>
      <figure className="container prose max-w-screen-lg bg-white shadow-xl">
            <GatsbyImage className="rounded-full w-36 h-36" 
            image={headShot} 
            alt={author} 
            />
            <div className="pt-2 pl-2 md:p-4 text-left space-y-4">
                <blockquote>
                <div className="text-xs md:text-sm text-red-400 font-bold">
                  Written by:  {author}
                </div>
                <div className="text-xs md:text-sm">
                   <Reactmarkdown>{bio}</Reactmarkdown>
                 </div>
                </blockquote>
                <figcaption className="text-xs md:text-sm">
                <div className="text-slate-600">
                Email him at: <a href={'mailto:'+ email} className="text-red-400">{email}</a>
                </div>
                </figcaption>
                <div className="text-sm font-bold text-red-400">
                 Date Published: {date}
              </div>
            </div>
        </figure>  
      <main className="pt-0">
        <BlocksRenderer blocks={article.blocks || []} />
      </main>
      <Cta />
    </Layout>

    )
}
// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiArticle.title
  const description = data.strapiArticle.description
  const shareImage = data.strapiArticle.cover.localFile.url
  const slug = data.strapiArticle.slug
  const url = `https://jackson.fi/article/` + slug
  return (
    <SEO title={title} description={description} shareImage={shareImage} url={url} />
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      createdDate
      blocks {
        ...Blocks
      }
      author {
        bio
        email
        name
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default ArticlePage
